import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../interfaces/store';
import INITIAL_STATE from '../config/userInput';
import {
  getOldLeadQualifierIdAndChoiceByCraftJsId,
  mutateUserInput,
} from '../helper/oldLeadQualifierMutationHelper';
import {
  UserInputFromComponent,
  OldLeadQualifier,
  OldChoiceForSubmission,
  LocalStateByCraftJsNodeId,
  UploadedFilesInfo,
} from '../interfaces/userInput';
import { TimeSlotObject } from '../../CraftJs/components/Calendar/interfaces';
import { useAppSelector } from '../root/hooks';

export const userInputSlice = createSlice({
  name: 'userInput',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: INITIAL_STATE,
  reducers: {
    setOldLeadQualifier: (state, action: PayloadAction<OldLeadQualifier[]>) => {
      state.oldLeadQualifier = action.payload;
    },
    setUserInput: (state, action: PayloadAction<UserInputFromComponent>) => {
      const isSingleOrMultipleChoice = ['radio', 'multiple'].includes(action.payload.type);
      const oldLeadQualifierIds = getOldLeadQualifierIdAndChoiceByCraftJsId({
        leadQualifierCraftJsNodeId: action.payload.leadQualifierCraftJsNodeId,
        choiceCraftJsNodeId: action.payload.choiceCraftJsNodeId,
        type: action.payload.type,
        oldLeadQualifier: state.oldLeadQualifier,
        currentPageId: action.payload.currentPageId,
      });
      const { leadQualifierId, choiceId, leadQualifierCraftJsNodeId, choiceCraftJsNodeId } =
        oldLeadQualifierIds;

      let newLocalStateValueForSingleOrMultiple: string[] = [];

      if (action.payload.type === 'multiple') {
        const currentLocalStateValue = (state.localStateByLeadQualifierCraftJsNodeId[
          leadQualifierCraftJsNodeId
        ]?.value ?? []) as string[];

        const valueIsExisting = currentLocalStateValue.some((id) => id === choiceCraftJsNodeId);

        if (valueIsExisting)
          newLocalStateValueForSingleOrMultiple = currentLocalStateValue.filter(
            (id) => id !== choiceCraftJsNodeId,
          );
        else
          newLocalStateValueForSingleOrMultiple = [...currentLocalStateValue, choiceCraftJsNodeId];
      }

      if (action.payload.type === 'radio')
        newLocalStateValueForSingleOrMultiple = [choiceCraftJsNodeId];

      const mutatedUserInput = mutateUserInput(
        {
          leadQualifierId,
          choiceId,
          type: action.payload.type,
          value: action.payload.value,
        },
        state.oldLeadQualifierToSubmit,
      );

      state.oldLeadQualifierToSubmit = mutatedUserInput;

      state.localStateByLeadQualifierCraftJsNodeId[leadQualifierCraftJsNodeId] = {
        value: isSingleOrMultipleChoice
          ? newLocalStateValueForSingleOrMultiple
          : action.payload.value,
      };
    },
    setSelectedTimeSlot: (state, action: PayloadAction<TimeSlotObject>) => {
      state.selectedTimeSlot = action.payload;
    },
    setIsButtonEnabled: (state, action: PayloadAction<boolean>) => {
      state.isButtonEnabled = action.payload;
    },
    setFileIds: (state, action: PayloadAction<number[]>) => {
      state.fileIds = [...state.fileIds, ...action.payload];
    },
    setUploadedFilesInfo: (state, action: PayloadAction<UploadedFilesInfo[]>) => {
      state.uploadedFilesInfo = action.payload;
    },
  },
});

export const {
  setUserInput,
  setOldLeadQualifier,
  setSelectedTimeSlot,
  setIsButtonEnabled,
  setFileIds,
  setUploadedFilesInfo,
} = userInputSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectOldLeadQualifierToSubmit = (state: RootState): OldChoiceForSubmission[] =>
  state.userInput.oldLeadQualifierToSubmit;

export const selectLocalStateByCraftJsNodeId = (state: RootState): LocalStateByCraftJsNodeId =>
  state.userInput.localStateByLeadQualifierCraftJsNodeId;

export const selectSelectedTimeSlot = (state: RootState): TimeSlotObject =>
  state.userInput.selectedTimeSlot;

export const selectIsButtonEnabled = (state: RootState): boolean => state.userInput.isButtonEnabled;

export const useFileIds = (): number[] =>
  useAppSelector((state: RootState) => state.userInput.fileIds);

export const useUploadedFilesInfo = (): UploadedFilesInfo[] =>
  useAppSelector((state: RootState) => state.userInput.uploadedFilesInfo);

export default userInputSlice.reducer;
