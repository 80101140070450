import React, { useEffect, useState } from 'react';
import { Wizard } from 'react-use-wizard';
import { GET_INITIAL_DATA } from '../graphql';
import FunnelDisabled from '../components/FunnelDisabled';
import { useQuery } from '@apollo/react-hooks';
import Page from '../../CraftJs/container/Page';
import { StandardSpinner } from '../../SharedUI/components/StandardSpinner';
import { FunnelPage } from '../../redux/interfaces/mainData';
import {
  getCommonPagesSortedByOrder,
  getThankYouPage,
  isThisComponentExistInCraftState,
  sortPagesByOrder,
} from '../helper';
import { useAppDispatch } from '../../redux/root/hooks';
import { setInitialMainData } from '../../redux/slices/mainData';
import {
  appendFontStylesAccordingToColorTheme,
  appendStylesAccordingToColorTheme,
} from '../../CraftJs/helper';
import { setOldLeadQualifier } from '../../redux/slices/userInput';
import CookieBanner from '../../CookieBanner/container/CookieBanner';
import Linktree from '../../Linktree/container/Linktree';
import { IoProvider } from 'socket.io-react-hook';
import FunnelHasNoPages from '../components/FunnelHasNoPages';
import ErrorMessageWithReload from '../../SharedUI/components/ErrorMessageWithReload';
import lz from 'lzutf8';
import { CRAFT_ELEMENTS } from '../../CraftJs/config/craftElements';
import AccountNeedsToPayInfo from '../../AccountNeedsToPayInfo/container/AccountNeedsToPayInfo';
import { getValueFromKeyValueArrayOfObjects } from '../../helper/array';
import { injectSegMetricsScript } from '../../helper/segmetrics';
import { getUtmFromUrl } from '../../CraftJs/components/ContactForm/helper';

declare global {
  interface Window {
    pagerCurrentStepId: string;
    trackVoiceMessagePlayback: Function;
  }
}

function Pager({
  trackingId,
  token,
}: {
  trackingId?: number | null;
  token?: string;
}): React.ReactElement {
  const { data, loading, error } = useQuery(GET_INITIAL_DATA);
  const dispatch = useAppDispatch();
  const [areVideosExisting, setAreVideosExisting] = useState(false);

  const {
    getFunnel: funnel,
    getUser: user,
    getFunnelPages: funnelPages,
    getThirdPartyTrackingSettings: thirdPartyTrackingSettings,
    getFunnelPresets: funnelPresets,
  } = data || {};

  useEffect(() => {
    if (
      funnelPages?.find((page: FunnelPage) => {
        const nodes = JSON.parse(lz.decompress(lz.decodeBase64(page.craftState)));
        return isThisComponentExistInCraftState(nodes, CRAFT_ELEMENTS.VIDEO);
      })
    ) {
      setAreVideosExisting(true);
    }
  }, [funnelPages]);

  useEffect(() => {
    if (!data) return;

    const segMetricsEnabledCoachIds = getValueFromKeyValueArrayOfObjects(
      data?.getGlobalSettingsFlags,
      'segMetricsEnabledCoachIds',
    );
    const segMetricsAiEnabled = !!segMetricsEnabledCoachIds?.includes(user?.id);

    dispatch(
      setInitialMainData({
        funnel: data.getFunnel,
        funnelColorTheme: data.getFunnelColorTheme,
        funnelPages: sortPagesByOrder(data.getFunnelPages),
        bookingSettings: data.getBookingSettings,
        subscriptionInfo: data.getSubscriptionInfo,
        thirdPartyTrackingSettings: data.getThirdPartyTrackingSettings,
        globalSettingsFlags: data.getGlobalSettingsFlags,
        factorsAiEnabled: false,
        bookingId: null,
        funnelPresets: funnelPresets.map((pre: any) => ({
          ...pre,
          preset: JSON.parse(pre?.preset),
        })),
      }),
    );
    dispatch(setOldLeadQualifier(data.getLeadQualifier));
    appendStylesAccordingToColorTheme(data.getFunnelColorTheme);
    if (segMetricsAiEnabled) injectSegMetricsScript();
  }, [data]);

  const handleAppendFontStylesAccordingToColorTheme = (checkedToggles: string[]): void => {
    appendFontStylesAccordingToColorTheme(
      data.getFunnelColorTheme,
      checkedToggles.includes('google-fonts'),
    );
  };

  if (loading) return <StandardSpinner />;
  if (error) return <ErrorMessageWithReload />;

  const forceEnabled = getUtmFromUrl('force-funnel-enabled');
  if (!funnel.enabled && !forceEnabled) return <FunnelDisabled />;

  const commonPages = getCommonPagesSortedByOrder(funnelPages);
  const thankYouPage = getThankYouPage(funnelPages);

  if (!commonPages.length) return <FunnelHasNoPages />;

  return (
    <IoProvider>
      {data?.getSubscriptionInfo?.remainingVisits <= 0 && <AccountNeedsToPayInfo />}
      <Linktree />
      <CookieBanner
        language={funnel.language}
        enabled={funnel.cookieBanner}
        onClose={handleAppendFontStylesAccordingToColorTheme}
        trackingId={trackingId}
        thirdPartyTrackingSettings={thirdPartyTrackingSettings}
        currentFunnelPageId={commonPages[0].id}
        facebookPixelEventAfterAcceptance={commonPages[0].facebookPixelEvent}
        linkedInEventAfterAcceptance={commonPages[0].linkedInEvent}
        tiktokPixelEventAfterAcceptance={commonPages[0].tikTokPixelEvent}
        googleTagTriggerAfterAcceptance={commonPages[0].googleTagTrigger}
        areVideosExisting={areVideosExisting}
        pageName={commonPages[0].name}
      />
      <Wizard startIndex={0}>
        {[...commonPages, thankYouPage].map((page: FunnelPage) => {
          return <Page key={page.id} {...page} trackingId={trackingId ?? 0} />;
        })}
      </Wizard>
    </IoProvider>
  );
}

export default Pager;
