import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';

// @ts-expect-error
import TagManager from 'react-gtm-module-defer';
import { Socket } from 'socket.io-client';
import TiktokPixel from 'tiktok-pixel';
import { isTrackingServiceAllowed } from '../../CookieBanner/helper';
import { getIpAndUserAgent } from '../../helper/deviceHelper';
import { getObjectId } from '../../helper/id';
import { ThirdPartyTrackingSettings } from '../../redux/interfaces/mainData';
import store from '../../redux/root/store';
import { SEND_FBAPI_EVENT } from '../graphql';
import { FireMeetovoTrackingProps } from '../interfaces';
import { getUrlParam } from '../../helper/url';

let pixelInitialized: boolean = false;
let tiktokPixelInitialized: boolean = false;
let linkedInEventInitialized: boolean = false;
let tagManagerInitialized: boolean = false;
let thirdPartyTrackingSettings = {} as ThirdPartyTrackingSettings;
const firedPixelEvents: string[] = [];
const firedTikTokEvents: string[] = [];
const firedLinkedInEvents: string[] = [];
const firedConversionsApiEvents: string[] = [];
const firedGoogleTags: string[] = [];
const pixelEventIds: { [key: string]: string } = {};

export const getEventId = (eventName: string): string => {
  let eventID = pixelEventIds[eventName];

  if (!eventID) {
    eventID = getObjectId(8);
    pixelEventIds[eventName] = eventID;
  }

  return eventID;
};

export const initGoogleTagManager = (
  settings: ThirdPartyTrackingSettings,
  eventAfterInit?: string,
): void => {
  const { enabled, providerId } = settings || {};
  const allowed = isTrackingServiceAllowed('google-tag-manager');
  const shouldFireTag = enabled && providerId && allowed && !tagManagerInitialized;

  if (!shouldFireTag) return;

  TagManager.initialize({
    gtmId: providerId,
  });

  tagManagerInitialized = true;

  fireGoogleTag(eventAfterInit);
};

export const fireGoogleTag = (tag: string = ''): void => {
  const allowed = isTrackingServiceAllowed('google-tag-manager');
  const shouldFireTag = !firedGoogleTags.includes(tag) && tag && allowed && tagManagerInitialized;

  if (!shouldFireTag) return;

  TagManager.dataLayer({
    dataLayer: {
      event: tag,
    },
  });

  firedGoogleTags.push(tag);
};

export const initFacebookPixel = (
  settings: ThirdPartyTrackingSettings,
  eventAfterInit?: string,
): void => {
  const { providerId, enabled } = settings || {};
  const allowed = isTrackingServiceAllowed('facebook-pixel');
  const shouldFirePixel = enabled && providerId && allowed && !pixelInitialized;

  if (!shouldFirePixel) return;

  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development', // enable logs
  };

  thirdPartyTrackingSettings = settings;
  pixelInitialized = true;
  ReactPixel.init(providerId, undefined, options);
  fireFacebookPixel('PageView');
  fireConversionsApi('PageView');
  fireFacebookPixel(eventAfterInit);
  fireConversionsApi(eventAfterInit);
};

export const fireFacebookPixel = (event: string = ''): void => {
  const { enabled } = thirdPartyTrackingSettings;
  const allowed = isTrackingServiceAllowed('facebook-pixel');
  const shouldFirePixel =
    !firedPixelEvents.includes(event) && enabled && event && allowed && pixelInitialized;

  if (!shouldFirePixel) return;

  ReactPixel.track(event, {}, { eventID: getEventId(event) });
  firedPixelEvents.push(event);
};

export const fireConversionsApi = (event: string = ''): void => {
  const { enabled } = thirdPartyTrackingSettings || {};
  const allowed = isTrackingServiceAllowed('facebook-conversion-api');
  const shouldFireConversionsApi =
    !firedConversionsApiEvents.includes(event) && enabled && allowed && pixelInitialized && event;

  if (!shouldFireConversionsApi) return;

  firedConversionsApiEvents.push(event);

  getIpAndUserAgent()
    .then((data) => {
      if (!data?.clientIp || !event) return;

      return window.apolloClient
        .mutate({
          mutation: SEND_FBAPI_EVENT,
          variables: {
            event,
            url: window.location.href,
            eventID: getEventId(event),
            clientUserAgent: data.clientUserAgent,
            clientIp: data.clientIp,
            fbclid: getUrlParam('fbclid'),
          },
        })
        .catch(console.error);
    })
    .catch(console.error);
};

export const fireMeetovoTracking = (
  socket: Socket,
  { currentPageId, nextPageId }: FireMeetovoTrackingProps,
): void => {
  const { userInput } = store.getState();
  const { oldLeadQualifierToSubmit } = userInput;
  const currentOldLeadQualifierId = parseInt(
    `${localStorage.getItem('meetovoCurrentOldLeadQualifierId') ?? '0'}`,
  );
  let leadQualifierState;

  if (currentOldLeadQualifierId) {
    const choices = oldLeadQualifierToSubmit
      .filter((sc) => sc.leadQualifierId === currentOldLeadQualifierId)
      .map((sc) => {
        const response: any = { choiceId: sc.choiceId };
        if (sc.numberValue) response.numberValue = sc.numberValue;
        if (sc.stringValue) response.stringValue = sc.stringValue;
        return response;
      });

    leadQualifierState = {
      leadQualifierId: currentOldLeadQualifierId,
      choices,
    };
  }

  const keyPrefix = 'craft-page_';
  const input = {
    interactionDate: new Date().toISOString(),
    key: `${keyPrefix}${currentPageId}`,
    futureKey: nextPageId ? `${keyPrefix}${nextPageId}` : null,
    leadQualifierState,
  };

  socket.emit('updateTracking', input);
};

let meetovoTrackingInitiated = false;
export const initMeetovoTracking = (socket: Socket, currentPageId: number): void => {
  if (meetovoTrackingInitiated) return;

  const keyPrefix = 'craft-page_';
  const input = {
    interactionDate: new Date().toISOString(),
    key: `${keyPrefix}${currentPageId}`,
    futureKey: null,
    leadQualifierState: null,
  };

  socket.emit('updateTracking', input);
  meetovoTrackingInitiated = true;
};

export const initTikTokPixel = (
  settings: ThirdPartyTrackingSettings,
  eventAfterInit?: string,
  pageName?: string,
): void => {
  const { providerId, enabled } = settings || {};

  const allowed = isTrackingServiceAllowed('tiktok-pixel');
  const shouldFirePixel = enabled && providerId && allowed && !tiktokPixelInitialized;

  if (!shouldFirePixel) return;

  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development', // enable logs
  };

  thirdPartyTrackingSettings = settings;

  TiktokPixel.init(providerId, {}, options)
    .then(() => {
      tiktokPixelInitialized = true;
      fireTikTokPixel('PageView', pageName);
      fireTikTokPixel(eventAfterInit, pageName);
    })
    .catch((e) => {
      console.log('Error Initializing TikTok Pixels', e);
    });
};

export const fireTikTokPixel = (event: string = '', pageName?: string): void => {
  const { enabled } = thirdPartyTrackingSettings;
  const allowed = isTrackingServiceAllowed('tiktok-pixel');

  const shouldFirePixel =
    !firedTikTokEvents.includes(event) && enabled && event && allowed && tiktokPixelInitialized;

  if (!shouldFirePixel) return;
  if (event === 'PageView') {
    TiktokPixel.pageView();
    return;
  }

  TiktokPixel.track(event, {
    content_id: pageName,
  });
  firedTikTokEvents.push(event);
};

export const initLinkedInEvents = (
  settings: ThirdPartyTrackingSettings,
  eventAfterInit?: string,
): void => {
  const { providerId, enabled } = settings || {};

  const allowed = isTrackingServiceAllowed('linkedin-event');
  const shouldFirePixel = enabled && providerId && allowed && !linkedInEventInitialized;
  if (!shouldFirePixel) return;

  LinkedInTag.init(providerId, 'dc', false);
  linkedInEventInitialized = true;
  fireLinkedInEvent(eventAfterInit);
};

export const fireLinkedInEvent = (event: string = ''): void => {
  const { enabled } = thirdPartyTrackingSettings;
  const allowed = isTrackingServiceAllowed('linkedin-event');

  const shouldFirePixel =
    !firedTikTokEvents.includes(event) && enabled && event && allowed && linkedInEventInitialized;

  if (!shouldFirePixel) return;
  LinkedInTag.track(event);
  // @ts-expect-error
  if (window.lintrk) window.lintrk(+event);

  firedLinkedInEvents.push(event);
};

export default [initFacebookPixel, fireFacebookPixel, fireConversionsApi];
