export type PageTypes = 'COMMON' | 'THANK_YOU';
export type ThirdPartyTrackingProvider =
  | 'google-tag-manager'
  | 'facebook-pixel'
  | 'facebook-conversion-api'
  | 'tiktok-pixel'
  | 'linkedin-event';

export interface FunnelPage {
  name: string;
  craftState: string;
  type: PageTypes;
  pageOrder: number;
  id: number;
  facebookPixelEvent?: string;
  linkedInEvent?: string;
  tikTokPixelEvent?: string;
  googleTagTrigger?: string;
  customJs?: string;
  redirectURL?: string;
  trackingId: number;
}

export enum FUNNEL_THEME_KEYS {
  HEADLINE_COLOR = 'headlineColor',
  TEXT_COLOR = 'textColor',
  ACCENT_COLOR = 'accentColor',
  BACKGROUND_COLOR = 'backgroundColor',
  CARD_BACKGROUND_COLOR = 'cardBackgroundColor',
  CARD_ICON_COLOR = 'cardIconColor',
  CARD_TEXT_COLOR = 'cardTextColor',
  ACCENT_COLOR_CONTRAST = 'accentColorContrast',
}

export interface FunnelColorTheme {
  accentColor: string;
  accentColorContrast: string;
  backgroundColor: string;
  cardBackgroundColor: string;
  cardIconColor: string;
  cardTextColor: string;
  headlineColor: string;
  fontFamily: string;
  fontLink: string;
  textColor: string;
}

export interface Funnel {
  id: number;
  customJs: string;
  language: string;
  enabled: boolean;
  brandingEnabled: boolean;
  cookieBanner: boolean;
}

export interface BookingSettings {
  bookingRangeInHours: number[];
}

export interface SubscriptionInfo {
  brandingEnabled: boolean;
  customBrandingEnabled: boolean;
  fileSizeLimitPerUploadInMB: number;
}

export interface ThirdPartyTrackingSettings {
  provider: ThirdPartyTrackingProvider;
  providerId: string;
  enabled: boolean;
}

export interface MainData {
  funnelColorTheme: FunnelColorTheme | {};
  funnel: Funnel | {};
  funnelPages: FunnelPage[] | [];
  bookingSettings: BookingSettings | {};
  subscriptionInfo: SubscriptionInfo | {};
  thirdPartyTrackingSettings: ThirdPartyTrackingSettings[] | [];
  globalSettingsFlags: Array<{ key: string; value: string }> | null;
  factorsAiEnabled: boolean;
  bookingId: null | number;
  funnelPresets: any[];
}

export interface ThirdPartyTrackingSettingsByProvider {
  facebookPixel: ThirdPartyTrackingSettings;
  facebookConversionApi: ThirdPartyTrackingSettings;
  googleTagManager: ThirdPartyTrackingSettings;
  tiktokPixel: ThirdPartyTrackingSettings;
  linkedInEvent: ThirdPartyTrackingSettings;
}

export enum funnelPresetElements {
  BUTTON = 'BUTTON',
  IMAGE = 'IMAGE',
  CAROUSEL = 'CAROUSEL',
  CHOICE = 'CHOICE',
}
