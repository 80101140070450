/* eslint-disable  @typescript-eslint/restrict-template-expressions */
import { getEnvironment } from '../helper/environment';
import { FunnelColorTheme } from '../redux/interfaces/mainData';

export const getContainerFinalSectionId = (id: string): string => `container-section-id-${id}`;

const { defaultDomain } = getEnvironment();
export const placeholderImage = `${defaultDomain}/dashboard/image-placeholder.png`;

export const appendStyles = ({
  _document,
  id,
  styles,
}: {
  _document: any;
  id: string;
  styles: string;
}): void => {
  _document.getElementById(id)?.remove();
  const styleElement = _document.createElement('style');
  styleElement.setAttribute('id', id);
  styleElement.setAttribute('type', 'text/css');
  const styleInner = _document.createTextNode(styles);
  styleElement.appendChild(styleInner);
  _document.body.appendChild(styleElement);
};

export const appendFontStylesAccordingToColorTheme = (
  theme: FunnelColorTheme,
  googleFontAllowed: boolean,
): void => {
  const { fontFamily, fontLink } = theme;

  const fontStyles = fontLink
    ? `
    @import url('${fontLink}');
    body { 
      font-family: '${fontFamily}', sans-serif; 
    }
`
    : `
    body { 
      font-family: '${fontFamily}', sans-serif; 
    }
  `;

  if (googleFontAllowed)
    appendStyles({
      id: 'brand-color-styles-font-v2',
      _document: document,
      styles: fontStyles,
    });
};

export const appendStylesAccordingToColorTheme = (theme: FunnelColorTheme): void => {
  const _document = document;
  const { headlineColor, textColor, accentColor, backgroundColor, accentColorContrast } = theme;
  const accentColorWithoutHash = `${accentColorContrast?.replace('#', '%23')}`;

  const styles = `
            body {
              background-color: ${backgroundColor} !important;
            }

            /* we need to set all to text color by default, because of the wrapping behaviour of draftjs */
            .rich-text__wrapper h1, .rich-text__wrapper h2, .rich-text__wrapper h3 {
              color: ${textColor};
            }
           
            .carousel-item  .rich-text__wrapper h1 > span[style*='font-size: 1.5em'],
            .carousel-item  .rich-text__wrapper h1 > span[style*='font-size: 2.5em'],
            .carousel-item  .rich-text__wrapper h1 > span[style*='font-size: 3.5em'],
            .carousel-item  .rich-text__wrapper h2 > span[style*='font-size: 1.5em'],
            .carousel-item  .rich-text__wrapper h2 > span[style*='font-size: 2.5em'],
            .carousel-item  .rich-text__wrapper h2 > span[style*='font-size: 3.5em'],
            .carousel-item  .rich-text__wrapper h3 > span[style*='font-size: 1.5em'],
            .carousel-item  .rich-text__wrapper h3 > span[style*='font-size: 2.5em'],
            .carousel-item  .rich-text__wrapper h3 > span[style*='font-size: 3.5em'],
            .carousel-item  .rich-text__wrapper p {
              color: white;
            }

            .rich-text__wrapper h1 > span[style*='font-size: 1.5em'],
            .rich-text__wrapper h1 > span[style*='font-size: 2.5em'],
            .rich-text__wrapper h1 > span[style*='font-size: 3.5em'] {
              color: ${headlineColor};
            }
            
            .rich-text__wrapper h2 > span[style*='font-size: 1.5em'],
            .rich-text__wrapper h2 > span[style*='font-size: 2.5em'],
            .rich-text__wrapper h2 > span[style*='font-size: 3.5em'] {
              color: ${headlineColor};
            }

            .rich-text__wrapper h3 > span[style*='font-size: 1.5em'],
            .rich-text__wrapper h3 > span[style*='font-size: 2.5em'],
            .rich-text__wrapper h3 > span[style*='font-size: 3.5em'] {
              color: ${headlineColor};
            }
            
            .rich-text__wrapper h1 > span[style*='font-size: 1em'], 
            .rich-text__wrapper h2 > span[style*='font-size: 1em'], 
            .rich-text__wrapper h3 > span[style*='font-size: 1em'],
            .rich-text__wrapper p  {
              color: ${textColor};
            }
            
            .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
              color:${textColor} !important;
              height: 100% !important;
              display: flex !important;
              font-size: 1em !important;
              align-items: center !important;
            }
            
            .voice-message-preview__image {
              border: 2px solid ${accentColor} !important;
            }
            
            .voice-message-preview__image .play-circle, 
            .voice-message-preview__image .play-circle:before, 
            .voice-message-preview__image .play-circle:after {
              background-color: ${accentColor} !important;
            }

            .voice-message-preview__image .play-circle * {
              color: ${accentColorContrast} !important;
            }
            
            .calendar .day-selector .day-selector__days li.active .dom {
              background: ${accentColor} !important;
            }
            
            .calendar .timeslot-wrapper .timeslot {
              border-color:${accentColor} !important;
              color:${accentColor} !important;
            }

            .popover-full {
              background: ${backgroundColor} !important;
            }
      
            .popover-full__close {
              color: ${accentColor} !important; 
            }

            .popover-full__content h4 {
              color: ${headlineColor} !important;
            }

            .popover-full__content p {
              color: ${textColor} !important;
            }

            .popover-full__content b {
              color: ${headlineColor} !important;
            }
      
            .cookie-settings a {
              color: ${headlineColor} !important;
            }
      
            .cookie-settings__buttons .btn.btn-link {
              color: ${textColor} !important;
            }
      
            .popover-full__content label {
              color: ${headlineColor} !important;
            }

            .cookie-banner__close {
              color: ${accentColor} !important; 
            }

            .cookie-banner__submit {
              background: ${
                accentColor?.toUpperCase() !== '#FFFFFF' ? accentColor : '#000000'
              } !important;
            }
      
            .cookie-banner .link-style {
              color: ${
                accentColor?.toUpperCase() !== '#FFFFFF' ? accentColor : '#000000'
              } !important;
            }
      
            .cookie-settings a {
              color: ${headlineColor} !important;
            }
      
            .linktree__link a {
              color: ${accentColor} !important;
            }

            .choice-inner-wrapper .form-check-input:checked {
              background-color: ${accentColor} !important;
              border-color: ${accentColor} !important;
            }
            
            .choice-inner-wrapper__active{
              border: 2px solid ${accentColor} !important;
              box-shadow: 0 8px 10px -5px #${reduceColorOpacity(
                accentColor,
                0.2,
              )}, 0 16px 24px 2px #${reduceColorOpacity(
    accentColor,
    0.3,
  )}, 0 6px 30px 5px #${reduceColorOpacity(accentColor, 0.2)} !important;
            }

            .rangeslider-horizontal .rangeslider__fill {
              background-color: ${accentColor} !important; 
            }

            .rangeslider-wrapper__value {
              color: ${textColor} !important;
            }

            .popover-full__content a, .footer-wrapper a.footer-wrapper__link {
              color: ${textColor} !important;
            }
            
            .contact-form__wrapper a,  .footer-wrapper a.footer-wrapper__link, .footer-wrapper .footer-wrapper__branding a, .footer-wrapper .footer-wrapper__branding {
              color: ${textColor} !important;
            }

            .contact-form__wrapper .button-component {
              background: ${accentColor};
              border-color: ${accentColor};
            }

            .form-check-label {
              color: ${textColor} !important;
            }

            .bottom-progress-bar .progress-bar {
              background-color: ${accentColor};
              color: rgba(255,255,255,0.5);
            }
            
            .choice-wrapper .form-check-input:checked {
              border-color: ${accentColorContrast || backgroundColor} !important;
            }
            .overlay .overlay-disabled-text{
              color: ${textColor};
            }

            .rangeslider-wrapper__value p {
              color: ${textColor} !important;
            }

            .form-check-input:checked[type=checkbox] {
              background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27${accentColorWithoutHash}%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e") !important;
            }
        `;

  appendStyles({
    _document,
    id: 'brand-color-styles-v2',
    styles,
  });
};

let setupHistoryBackForWizardInitialized = false;
export const setupHistoryBackForWizard = (): void => {
  window.history.pushState(null, document.title, window.location.href);

  if (setupHistoryBackForWizardInitialized) return;
  setupHistoryBackForWizardInitialized = true;

  window.addEventListener('popstate', function (event) {
    const nextStepIndex = getPreviousStepFromHistory();
    window?.goToStep(nextStepIndex);
    removeLastStepFromHistory();
  });
};

const getStepHistory = (): number[] =>
  JSON.parse(localStorage.getItem('meetovoStepHistory') ?? '[]');

const setStepHistory = (stepHistory: number[]): void =>
  localStorage.setItem('meetovoStepHistory', JSON.stringify(stepHistory));

export const addToStepHistory = (index: number): void => {
  const currentStepHistory = getStepHistory();
  setStepHistory([...currentStepHistory, index]);
};

export const removeLastStepFromHistory = (): void => {
  const currentStepHistory = getStepHistory();
  currentStepHistory.pop();
  setStepHistory(currentStepHistory);
};

export const getPreviousStepFromHistory = (): number => {
  const currentStepHistory = getStepHistory();
  return currentStepHistory[currentStepHistory.length - 2] ?? 0;
};

export const sanitizeText = (str: string): string => {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, ' ');
};

export function reduceColorOpacity(color: string, opacity: number): string {
  if (color.includes('#')) {
    color = color.split('#')[1];
  }
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

function dec2hex(dec: number): string {
  return dec.toString(16).padStart(2, '0');
}

export function getRandomId(len?: number): string {
  const arr = new Uint8Array((len ?? 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}
export const getElementColor = (clr: string | undefined, theme: FunnelColorTheme | {}): string => {
  // @ts-expect-error
  return theme[clr] || clr;
};

export const hexToRGBA = (hexOrRGBA: string, alpha: number): string => {
  const isHex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hexOrRGBA);

  if (isHex) {
    const hexValue = hexOrRGBA.replace(/^#/, '');

    if (hexValue.length === 3) {
      const r = parseInt(hexValue[0] + hexValue[0], 16);
      const g = parseInt(hexValue[1] + hexValue[1], 16);
      const b = parseInt(hexValue[2] + hexValue[2], 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    if (hexValue.length === 6) {
      const r = parseInt(hexValue.slice(0, 2), 16);
      const g = parseInt(hexValue.slice(2, 4), 16);
      const b = parseInt(hexValue.slice(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
  } else {
    const rgbaParts = hexOrRGBA.match(/(\d+(\.\d+)*)/g);
    if (rgbaParts && rgbaParts.length === 4) {
      return `rgba(${rgbaParts[0]}, ${rgbaParts[1]}, ${rgbaParts[2]}, ${alpha})`;
    }
  }

  return `rgba(255, 255, 255, ${alpha})`;
};

export function convertColorToRGBA(inputColor: string, opacity: any): string {
  if (inputColor.startsWith('rgba')) {
    const colorComponents = inputColor.split(',');
    colorComponents[3] = opacity;
    return `${colorComponents.join(',')})`;
  }

  if (/^#([0-9A-F]{3}){1,2}$/i.test(inputColor)) {
    let hexColor = inputColor.replace('#', '');

    if (hexColor.length === 3) {
      hexColor = hexColor
        .split('')
        .map((char) => char + char)
        .join('');
    }

    const red = parseInt(hexColor.slice(0, 2), 16);
    const green = parseInt(hexColor.slice(2, 4), 16);
    const blue = parseInt(hexColor.slice(4, 6), 16);

    return `rgba(${red},${green},${blue},${opacity})`;
  } else {
    return inputColor;
  }
}

export const getChoiceTemplateClassName = (templateKey?: TEMPLATE_KEYS): string => {
  switch (templateKey) {
    case TEMPLATE_KEYS.ICON_ON_RIGHT:
      return 'choice-icon-on-right';
    case TEMPLATE_KEYS.ICON_ON_CENTER:
      return 'choice-icon-on-center';
    case TEMPLATE_KEYS.ICON_ON_LEFT:
      return 'choice-icon-on-left';
    case TEMPLATE_KEYS.ICON_ON_TOP:
      return 'choice-icon-on-top';
    default:
      return 'choice-icon-on-right';
  }
};

export const addOpacityToLinearGradient = (input: string, opacity: number): string => {
  // Regular expression to match rgba codes
  const rgbaRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/gi;

  // Replace RGBA codes with modified opacity
  const modifiedGradient = input.replace(rgbaRegex, (_, r, g, b, a) => {
    const newOpacity = a ? (parseFloat(a) * opacity).toString() : opacity.toString();
    return `rgba(${r}, ${g}, ${b}, ${newOpacity})`;
  });

  return modifiedGradient;
};

export const replacePlaceHoldersInCraftState = (craft: string): string => {
  const paramsObj = getQueryParams();
  let tempState = craft;
  for (const key in paramsObj) {
    const variableToBeReplaced = key.match(/MTP_(.+)/)?.[1];
    if (key.startsWith('MTP_')) {
      tempState = tempState.replaceAll(`[[${variableToBeReplaced}]]`, paramsObj[key]);
    }
  }
  return tempState;
};

export const getQueryParams = (): { [key: string]: string } => {
  const queryParams = new URLSearchParams(window.location.search);
  const paramsObj = Array.from(queryParams.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: queryParams.get(val) }),
    {},
  );
  return paramsObj;
};

export enum TEMPLATE_KEYS {
  ICON_ON_LEFT = 'iconOnLeft',
  ICON_ON_RIGHT = 'iconOnRight',
  ICON_ON_TOP = 'iconOnTop',
  ICON_ON_CENTER = 'iconOnCenter',
}

export const allowedFontSizes = {
  2: 'S',
  4: 'M',
  6: 'L',
};

export const normalizeHref = (href: string): string => {
  if (href && !href.startsWith('http://') && !href.startsWith('https://')) {
    return `https://${href}`;
  }
  return href;
};
